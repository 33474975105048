@tailwind base;
@tailwind components;
@tailwind utilities;

/* font-family: 'Comforter', cursive;
font-family: 'Noto Sans Kawi', sans-serif; */


@layer components{
  .align-element{
    @apply mx-auto max-w-7xl px-8;
  }
}



#tooltip{
  overflow: visible !important;
  background-color: transparent;
  box-shadow: none;
}


#tooltip svg:nth-child(2){
  display: none;
}

.logo{
  font-family: 'Comforter', cursive;
}



#mainpic{
  animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}


@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}